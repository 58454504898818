.active {
	background-color: #fafafa;
	padding: 1rem;
	color: #ff8b51;
	border-left: 5px solid #ff8b51;
}

.nav_links {
	text-decoration: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 1rem;
	padding: 1rem;
}

#sidenavbar_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: white;
	justify-content: flex-start;
	height: 100%;
	border-radius: 10px;
}
